<template>
	<div class="wxpay">
		<img :src="logo" alt="" class="wx-logo" />
		<div class="wx-container">
			<div class="wx-box">
				<div class="qrcode" ref="qrCodeUrl"></div>
				<span>请打开微信扫码支付</span>
			</div>
			<div class="wx-order">
				<p>订单编号：<span id="orderSn">{{ payMsg.orderId }}</span></p>
				<p>订单金额：<span id="payPrice">{{ payMsg.price }}</span>元</p>
			</div>
		</div>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
	data() {
		return {
			logo: require('@/assets/images/logo.png'),
			payMsg: {},
			timer:'',
			clock:''
		};
	},
	mounted() {
		let that = this;
    that.payMsg = JSON.parse(sessionStorage['payCode']);
    var qrcode = new QRCode(this.$refs.qrCodeUrl, {
			text: that.payMsg.code,
			width: 220,
			height: 220,
			colorDark: '#000000',
			colorLight: '#ffffff',
			correctLevel: QRCode.CorrectLevel.H
		});
		this.clock = setTimeout(this.checkOrderStatus,5000);
	},
	beforeDestroy() {
		clearTimeout(this.clock)
		clearInterval(this.timer);
	},
	methods: {
		// 检查订单
		checkOrderStatus:function () {
			let that = this;
			let count = 30;
			that.timer = setInterval(()=>{
				if (count > 0) {
					that.$http({
            isMaterial: true,
						url:'wechat/pay_query',
						method:'GET',
						params:{
							order_no: that.payMsg.orderId
						}
					}).then(res => {
						if( res.result.exists ){
							that.$message.success('支付成功');
							clearInterval(that.timer);
							setTimeout(()=>{
								sessionStorage.removeItem('addressMsg');   // 清除缓存中的地址信息
								that.$router.push({path:'/place/orders',query: { type: 2 }});
							},1000);
						}
					}).catch(err => {
						console.error(err);
					})
					count --;
				} else {
					clearInterval(that.timer);
					this.$confirm('请确认您是否已经支付', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
					}).then(() => {
						sessionStorage['orderType'] = 1;
						that.$router.push('/place/orders');
					}).catch(() => {
						sessionStorage['orderType'] = 2;
						that.$router.push('/place/orders');
					});
				}
			},3000)
		}
	}
};
</script>

<style scoped>
.wxpay{ height: 90vh }
.wx-logo {
	position: absolute;
	left: 50%;
	top: 8%;
	margin-left: -105px;
}
.wx-container {
	width: 400px;
	padding-bottom: 40px;
	position: fixed;
	left: 50%;
	margin-left: -200px;
	top: 15%;
	background: linear-gradient(105deg, rgb(63, 129, 210) 0%, rgb(47, 197, 85) 100%);
	border-radius: 12px;
}
.wx-container .wx-box {
	width: 90%;
	margin: 5% auto;
	border-radius: 10px;
	background: #ffffff;
	padding-top: 30px;
	padding-bottom: 20px;
	position: relative;
	text-align: center;
}
.wx-container .wx-box img {
	width: 220px;
	height: 220px;
	position: absolute;
	left: 70px;
	top: 30px;
}
.wx-container .wx-box span {
	display: inline-block;
	width: 100%;
	text-align: center;
	color: #3f7ad2;
	font-size: 20px;
	font-weight: 500;
}
.wx-container .qrcode {
	text-align: center;
	margin: 0 auto;
	width: auto;
	display: inline-block;
	margin-bottom: 15px;
}
.wx-container .wx-order {
	padding-left: 5%;
	padding-top: 10px;
}
.wx-container .wx-order p {
	font-size: 18px;
	color: #ffffff;
	font-weight: 500;
	margin-bottom: 10px;
}
.wx-container .wx-order p span {
	color: #ffffff;
}
</style>
